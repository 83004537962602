.ant-tabs-nav {
  @apply px-10 !mb-0;
}

.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-tab-btn:hover,
.ant-tabs-tab:hover {
  @apply !text-orange;
}

.ant-tabs-ink-bar {
  @apply !bg-orange;
}
