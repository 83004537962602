.ant-pagination-item-active {
  @apply !bg-orange !border-orange;
}

.ant-pagination-item-active a {
  @apply !text-white;
}

.ant-pagination-total-text {
  @apply !mr-auto;
}
